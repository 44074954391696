import React from 'react';
import about from "../images/pages/kitchen.jpg";

function Aboutus() {
    return (
        <div>
            <section className="intro-section spad">
                <div className="container">
                    <div className="section-title">
                        <div className="headerTitle">G13 Construction LTD</div>
                    </div>
                    <div className="row intro-first">
                        <div className="col-lg-6 order-lg-2">
                            <img src={about} alt=""/>
                        </div>
                        <div className="col-lg-6 order-lg-1">
                            <div className="about-text">
                                <p>G13 Construction Limited is a leading Construction company in the UK and is currently focussed on the residential property and commercial construction arena. We pride ourselves in understanding  our client’s needs and expectations from the very onset of a project. It’s our objective to ensure our experience in the industry results in the highest standards and professionalism, our in-house services with an eye for technical innovation and sustainable construction solutions always deliver a best in class result.</p>
                                <p>Our aim is to  provide flexibility with all our private and corporate clients this enables us to meet their requirements and while providing a  consultancy service around several stages of a new build project. Starting with design, architecture services, planning, permissions, surveying, engineering, land evaluation, building, decorating, certification until acquisition for the property. This is a total turnkey service that sets us above our competitors.</p>
                                <p>Our team also includes specialists for refurbishment and renovations projects to provide a breath of services -specialising  in extension projects and property development, including: scheme selection, analysis, design, development, liaising with statutory bodies, interior design and project management of construction process.</p>
                                <p>It’s our role to take the strain and fine tune all aspects of the project to work with you for your dream property.</p>
                                <p>Our pricing model is very competitive and flexible as per the client’s requirements and budget expectations. When providing estimates we allow for the best materials with solutions at the best price and our team of talented professionals is committed to delivering finished projects on time.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}

export default Aboutus;
