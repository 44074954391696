import React from 'react';
import {Switch , Route } from 'react-router-dom';
import Home from './Home';
import Contact from './Contact';
import Pricing from './Pricing';
import Aboutus from "./Aboutus";
import Portfolio from "./Portfolio";
import Services from "./Services";
function Main() {
  return (
    <main>
        <Switch>
            <Route exact path="/" component={Home}/>
            <Route path="/contact" component={Contact}/>
            <Route path="/pricing" component ={Pricing}/>
            <Route exact path="/about" component={Aboutus}/>
            <Route path="/project" component={Portfolio}/>
            <Route path="/services" component ={Services}/>
        </Switch>
    </main>
  );
}

export default Main;
