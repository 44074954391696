import React from 'react';
import kit from "../images/pages/kit.jpg";
import back from "../images/pages/back.jpg";
import bath from "../images/pages/batg.jpg";
import des from "../images/pages/des.jpg";


function Services() {
  return (
      <div>
          <section className="intro-section spad">
              <div className="container">
                  <div className="section-title">
                      <div className="headerTitle">Services</div>
                      <p>G13 Construction Ltd offer a complete end-to-end service including property construction, refurbishment, renovation and maintenance. </p>
                  </div>
                  <div className="row intro-first">
                      <div className="col-lg-6 order-lg-2">
                          <img src={des} alt=""/>
                      </div>
                      <div className="col-lg-6 order-lg-1">
                          <div className="about-text">
                              <div className="head">Design & Construction of New Buildings </div>
                              <p>Project management, build services, Site surveying, Drawings & plan generation, Detailed cost analysis & budgeting
                              </p>
                          </div>
                      </div>
                  </div>
                  <div className="hr1"/>
                  <div className="row">
                      <div className="col-lg-6">
                          <img src={back} alt=""/>
                      </div>
                      <div className="col-lg-6 ">
                          <div className="about-text">
                               <div className="head">Extensions of Existing Buildings & Sites</div>
                              <p>G13 Construction Limited can offer a full range of services to fulfil a complete range of requirements for a project - ranging from architectural design, planning services, project management, construction and interior finishing. We specialise in all aspects of property refurbishment from demolitions and structural work through to impeccable finishes in joinery, marble, cornicing, flooring and specialist decorations. Our constant work with the best interior designers in the industry has provided us with an enviable client list, with many of our customers returning for additional services, which is testament to the relationships we have built over the years.</p>
                              </div>
                      </div>
                  </div>
                  <div className="hr1"/>

                  <div className="row intro-first">
                      <div className="col-lg-6 order-lg-2">
                          <img src={kit} alt=""/>
                      </div>
                      <div className="col-lg-6 order-lg-1">
                          <div className="about-text">
                              <div  className="head">Building Internal Fit-out & Repairs</div>
                              <p>We specialise in high-specification, bespoke projects; working closely with the clients. we consistently deliver the top-quality workmanship and luxury finished properties. The expectations of their clients, however, ensure that the job does not finish when the last tool is put down – each and every property they work on is handed over in spotless condition, perfectly showcasing their work and their clients’ properties.</p>
                          </div>
                      </div>
                  </div>
                  <div className="hr1"/>

                  <div className="row">
                      <div className="col-lg-6">
                          <img src={bath} alt=""/>
                      </div>
                      <div className="col-lg-6 ">
                          <div className="about-text">
                              <div className="head">Ground-works & Site Clearances</div>
                              <p>Earthworks, Soil excavation, Retaining walls, Hazardous waste removal, Structure demolition</p>
                          </div>
                      </div>
                  </div>

              </div>
          </section>

      </div>
  );

}

export default Services;
