import React from 'react';
import {Field, Form} from 'react-final-form';
import axios from 'axios';


const required = value => (value ? undefined : 'Required');

class Contact extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            clicked: '',
            errorMsg: ''
        };
        this.showResults = this.showResults.bind(this);
    }


    showResults(values) {
        var self = this;
        axios({
            method: 'POST',
            url: 'https://kayukltd.com/webapi/softwarecl/submit?toaddress=ask@g13construction.com',
            data: values,
            headers: {'Context-Type': 'applicaiton/json; charset=utf-8'}

        }).then(function (response) {
            console.log(response);
            if (response.data === 'Sent')
                self.setState({clicked: 'done'});
            else
                self.setState({errorMsg: 'There has been some problem with the page, please contact us by other methods'});

        }).catch(function (error) {
            self.setState({errorMsg: 'There has been some problem with the page, please contact us by other methods'});
        });

        // window.alert(JSON.stringify(values, undefined,2));
    }

    render() {


        return (
            <div>
             <section className="intro-section spad">
                    <div className="container">
                        <div className="App">

                            <div id="contact" className="section wb">
                                <div className="container">
                                    <div className="section-title text-center">
                                        <div className="headerTitle">Get in touch</div>
                                        <p className="lead">Write to us, email us, phone us or fill in our contact form<br/> we will
                                            get back to you soon.</p>
                                    </div>

                                    {this.state.clicked ?  <div className="section-title text-center"><p className="lead">Thanks for submitting the request, Our Team will get back to you very shortly.</p> </div>: <div className="row">
                                        <div className="col-md-8 col-md-offset-2">
                                            <div className="contact_form">
                                                <Form onSubmit={this.showResults}>
                                                    {({handleSubmit, values, submitting}) => <form onSubmit={handleSubmit}
                                                                                                   className="row">


                                                        <Field name="firstName" component="input" validate={required}
                                                               subscription={{
                                                                   value: true,
                                                                   active: true,
                                                                   error: true,
                                                                   touched: true
                                                               }}>
                                                            {({input, meta}) => (
                                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"><label>First
                                                                    Name * </label> {meta.error && meta.touched &&
                                                                <span className="errorText">{meta.error}</span>}
                                                                    <input {...input} className="form-control"/>
                                                                </div>)}</Field>

                                                        <Field name="lastName" component="input" validate={required}>
                                                            {({input, meta}) => (
                                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"><label>Last
                                                                    Name * </label> {meta.error && meta.touched &&
                                                                <span className="errorText">{meta.error}</span>}
                                                                    <input {...input} className="form-control"/>
                                                                </div>)}</Field>

                                                        <Field name="Email" component="input" validate={required}>
                                                            {({input, meta}) => (
                                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"><label>Email
                                                                    * </label> {meta.error && meta.touched &&
                                                                <span className="errorText">{meta.error}</span>}
                                                                    <input {...input} className="form-control"/>
                                                                </div>)}</Field>
                                                        <Field name="Phone" component="input" validate={required}>
                                                            {({input, meta}) => (
                                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"><label>Phone
                                                                    * </label> {meta.error && meta.touched &&
                                                                <span className="errorText">{meta.error}</span>}
                                                                    <input {...input} className="form-control"/>
                                                                </div>)}</Field>
                                                        <Field name="Details" component="input" validate={required}>
                                                            {({input, meta}) => (
                                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"><label>Your
                                                                    Details * </label> {meta.error && meta.touched &&
                                                                <span className="errorText">{meta.error}</span>}
                                                                    <textarea {...input} className="form-control"/>
                                                                </div>)}</Field>
                                                        <div className="errorText">{this.state.errorMsg}</div>

                                                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 text-center">
                                                            <button className="btn btn-light btn-radius btn-brd grd1 btn-block"
                                                                    type="submit" disabled={submitting}>Submit
                                                            </button>
                                                        </div>
                                                        {/* <pre>{JSON.stringify(values,undefined, 2)}</pre>*/}
                                                    </form>}

                                                </Form>
                                            </div>
                                        </div>
                                    </div>}


                                    <div className="row">
                                        <div className="col-md-offset-1 col-sm-10 col-md-10 col-sm-offset-1 pd-add">
                                            <div className="address-item">
                                                <div className="address-icon"><i className="fa fa-envelope"
                                                                                 aria-hidden="true"></i></div>
                                                <h3>Email Us</h3><p>ask@g13construction.com</p></div>
                                                        <div className="address-item">
                                        <div className="address-icon"><i className="icon">	&#x1F3A7;</i></div>
                                        <h3>Call Us</h3><p>+44 7707711222</p></div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

            </div>
                </section>


            </div>
        );
    }
}

export default Contact;
