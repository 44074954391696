import React from 'react';
import first from '../images/pages/first.jpg';
import third from '../images/pages/1.jpg';

import {Link} from "react-router-dom";

function Home() {
    return (
        <div>
            <section className="intro-section spad" >
{/*
                <div className="section-title" style={{backgroundImage: `url(${kitchen})`, backgroundSize: '100% 100%'}}>
*/}
                <div className="container">
                    <div className="section-title" >
{/*
                        <div className="headerTitle">The Builders Driven by Commitment for your Project: New Build ,Refurbish and Extensions</div>
*/}
                        <div className="headerTitle">New Build {'  '}|  {'  '}Design {'  '} |  {'  '}Planning  {'  '}|  {'  '}Refurbish {'  '} | {'  '} Extensions</div>
                    </div>

                    <div className="row intro-first">
                        <div className="col-lg-6 order-lg-2">
                            <img src={third} alt=""/>
                        </div>
                        <div className="col-lg-6 order-lg-1">
                            <div className="about-text">
                                <h3>A Leading Construction Company</h3> <p>G13 Construction Limited has been established for over a decade specialising in construction projects with eye catching designs. Our experience and expertise ensures we are a leader in our business providing our customers with the confidence that’s required when embarking on large scale projects.</p>
                                <p>Whether the requirement is for a new build project, refurbishment work to your existing dwelling, the construction of an extension or a basement space we cover all your potential needs. </p>
                                <p>We pride ourselves on a high level of service, professionalism and expertise. This enables us to build long term relationships with our clients including some of London’s leading interior designers and architects, as well as private clients and property developers.</p>
                                <Link to="/services" className="readmore-btn">Find out more</Link>

                            </div>
                        </div>
                    </div>
                    <div className="hr1"/>

                    <div className="row">
                        <div className="col-lg-6">
                            <img src={first} alt=""/>
                        </div>
                        <div className="col-lg-6 ">
                            <div className="about-text">
                                <h3>Design and Build</h3>
                                <p>In building some of the regions glittering gems G13 Construction has a formidable reputation of being a dependable design and construction firm – this has enabled us to secure the accolade and loyalty of customers, suppliers and peers for quality execution, integrity and efficiency.</p>
                                <Link to="/services" className="readmore-btn">Find out more</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}

export default Home;
