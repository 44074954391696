import React from 'react';
import  '../../App.css';
import logo from '../../images/logos/logo1.png';
import {Link} from "react-router-dom";
class Header extends React.Component{

    constructor(props)
    {
        super(props);
        this.state={
          butClicked:true
        };
    }

    butClicked()
    {
        this.setState({butClicked: !this.state.butClicked})
    }
    render(){
let stl=this.state.butClicked?'newNav':'newNavMobile';
  return (<div>

      <header className="header-section">
          <Link to="/" className="site-logo">
              <img src={logo} alt=""/>
          </Link>
          <nav className="header-nav">
              <div type="button" className="MobileButton"  onClick={this.butClicked.bind(this)}> &#9776; Menu</div>
              <ul className={stl}>
                  <li className="newNavLi"><Link className="newNavLiA" to="/">Home</Link></li>
                  <li className="newNavLi"><Link  className="newNavLiA" to="/about">About us</Link></li>
                  <li className="newNavLi"><Link className="newNavLiA" to="/services">Services</Link></li>
{/*
                  <li className="newNavLi"><Link className="newNavLiA" to="/project">Projects</Link></li>
*/}
                  <li className="newNavLi"><Link className="newNavLiA" to="/contact">Contact Us</Link></li>
              </ul>
          </nav>
      </header>

  </div>
  );
}}

export default Header;
