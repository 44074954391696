import React from 'react';
import nhbc from "../../images/logos/nhbc.jpg";


function Footer() {
  return (
    <div className="App">
        <footer className="footer-section">
            <div className="container">
{/*
                <div className="row text-white">
                    <div className="col-lg-4">
                        <div className="footer-widger">
                            <div className="about-widget">
                                <div className="aw-text">
                                    <img src="img/footer-logo.png" alt=""/>
                                        <p>Donec eget efficitur ex. Donec eget dolor vitae eros feugiat tristique id
                                            vitae massa. Proin vulputate cong ue rutrum. Fusce lobortis a enim eget
                                            tempus. </p>
                                        <a href="#" className="site-btn">we are hiring</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-6">
                        <div className="footer-widger">
                            <h2>Company</h2>
                            <ul>
                                <li><a href="#">About us</a></li>
                                <li><a href="#">Services</a></li>
                                <li><a href="#">Clients</a></li>
                                <li><a href="#">Testimonials</a></li>
                                <li><a href="#">Carrers</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-6">
                        <div className="footer-widger">
                            <h2>For Buyers</h2>
                            <ul>
                                <li><a href="#">Buy with us</a></li>
                                <li><a href="#">Papers</a></li>
                                <li><a href="#">Clients</a></li>
                                <li><a href="#">Testimonials</a></li>
                                <li><a href="#">Homes</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-6">
                        <div className="footer-widger">
                            <h2>For Sellers</h2>
                            <ul>
                                <li><a href="#">Seel With us</a></li>
                                <li><a href="#">What do You Need</a></li>
                                <li><a href="#">Clients</a></li>
                                <li><a href="#">Testimonials</a></li>
                                <li><a href="#">Guideline</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-6">
                        <div className="footer-widger">
                            <h2>For Renters</h2>
                            <ul>
                                <li><a href="#">Rent with us</a></li>
                                <li><a href="#">Guidelines</a></li>
                                <li><a href="#">Apartments</a></li>
                                <li><a href="#">Flats</a></li>
                                <li><a href="#">Houses</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
*/}
<div className="logoHolder">
                <img className="nhbc" src={nhbc} alt="NHBC"/>
</div>
                <div
                    className="copyright">
                    Copyright &copy;  {(new Date().getFullYear())} G13 Construction LTD. All Rights Reserved. Registered in England and Wales. Registration Number: 07446702.  Powered by <a
                    href="https://softwarecl.co.uk" target="_blank"  rel="noopener noreferrer" >Software Consultant Ltd</a>
                </div>
            </div>
        </footer>


    </div>
  );
}

export default Footer;
