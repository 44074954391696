import React from 'react';


function Pricing() {
  return (
    <div className="App">
   THis is the Pricing Page
    </div>
  );
}

export default Pricing;
